// $c - dark:#000;
// $c - light: #fff;
// $c - green - 100:#278765;
// $c - green - 70:#5CB291;
// $c - green - 40:#5CB291;
// $c - purple - 100:#73237D;
// $c - purple - 70: #AD6CAF;
// $c - purple - 40: #E6B4E1;
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
if (process.client) { 

    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
}

class ScrollControl { 
    state: any;
    sx: any;
    sy: any;
    p: any;
    lastPos: any;
    newPos: any;
    timer: any;
    delta: any = 0;
    sdelta = 0;
    delay = 50;
    aframe: any;
    top = 0;
    pstate = reactive({
            y:0,
            x:0,
            sy:0,
            delta:0,
            sdelta:0,
            newPos:0
        })
    active: boolean = false;

    constructor() {
     


    }
    clear() {
        let {lastPos, delta} = this
        lastPos = null;
        delta = 0;
    }
    setActive(state: any) {
        if (!this.active) {
            this.state = state
            document?.addEventListener("scroll", this.handleScrollEvent.bind(this))
            this.aframe = requestAnimationFrame(this.animate.bind(this))
            
        }
    }
    animate() {
        //  console.log("animating",this.delta,this.sdelta)
        this.newPos = window.scrollY;
        if (this.lastPos != null) {
            this.delta = this.newPos - this.lastPos;
        }
        
        this.lastPos = this.newPos;
        this.sdelta -= ((this.sdelta - this.delta)*.05)
        // console.log(this.sdelta)
        if (Math.abs(this.sdelta)< .05) {
            this.sdelta = 0
        } else {
            this.aframe = requestAnimationFrame(this.animate.bind(this))
            
        }
        this.pstate.delta = this.delta
        this.pstate.sdelta = this.sdelta
        // console.log(this.sdelta)
    }
    handleScrollEvent(event: any) {
        // let {newPos,lastPos,delta,timer,delay} = this
        // console.log("here!",this)
        // newPos = window.scrollY;
        // if (lastPos != null) {
        //     delta = newPos - lastPos;
        // }
        // lastPos = newPos;
        // clearTimeout(timer);
        // timer = setTimeout(this.clear.bind(this), delay);
        // console.log(delta)
        // return delta;
        this.animate()
        // // console.log(event.clientX)
        // this.sx = event.s

        this.pstate.sy = event.target.documentElement.scrollTop
        // console.log(event.target.documentElement.scrollTop)
        // this.sy = event.clientY
    }
}
class MouseControl {
    state:any;
    x:any;
    y: any;
    active: boolean = false;
        
    constructor() {


       
    }
    setActive(state: any) {
        if (!this.active) {
            this.state = state
             window?.addEventListener("mousemove", this.handleMouseEvent.bind(this))
        }
    }
    handleMouseEvent(event:MouseEvent) {
        // console.log(event.clientX)
        this.x = event.clientX
        this.y = event.clientY
    }
}
const icons = import.meta.glob("@/assets/images/icons/*.svg", {
    query: '?raw', import: 'default',
    eager: true,
});
const state = reactive({
    mouse: ref(new MouseControl()),
    scroll: ref(new ScrollControl()),
    uiconfig: { fps: 60, debug: false, animation: true },
    colors: {
        dark: "#000",
        light: "#fff",
        greens: ["#278765", "#5CB291", "#91DCBE"],
        purples: ["#73237D", "#AD6CAF", "#E6B4E1"]
    },
    animation_values: {
      'from-top':{y:"-=25vh"},  
      'from-bottom':{y:"+=25vh"},  
      'from-left':{x:"-=25vh"} ,
      'from-right':{x:"+=25vh"}  
    },
    icons: icons
})

export function useUIControl() {
    // console.log(process)
    if (!state.mouse.active && process.client) {
        state.mouse.setActive(state)
    }
    if (!state.scroll.active && process.client) {
        state.scroll.setActive(state)
    }
    const cleanUp = () => {
        // console.log(gsap.globalTimeline.getChildren(true,true,true))
        gsap.globalTimeline.getChildren(true, true, true).forEach(t => t.kill())
    }
    const handleAnimation = (comp:any,settings:any) => {
        // console.log("handleAnimation(", comp.value, ")", `– ${settings}`)
        if (settings.type == "footer") {
            gsap.from(comp.value.querySelectorAll(".footer-section, .footer__background"), {
                scrollTrigger: {
                    trigger: comp.value,
                    start:"top-=200px bottom",
                    end: "bottom-=200px bottom-=200px",
                    //  start: "top top",
                    // toggleClass:'animated--waiting',
                    // end: "center",
                    scrub: 1,
                    // pin:true,
                    // markers: true

                }, overwrite: true, transformOrigin: "center center", ease: "expo.out", duration: 1.5,y:"+=200px",stagger:-.4
            })
        }
        
        if (state.uiconfig.animation && process.client && settings.a && settings.layout.a_enabled) {
            // console.log()
            // console.log(comp,settings)
            const { type, link_scroll } = settings.a

                switch (type) {
                    case "default":
                        gsap.from(comp.value, {
                            scrollTrigger: {
                                trigger: comp.value,
                                //  start:"top",
                                // end: "50%",
                                //  start: "top top",
                                // toggleClass:'animated--waiting',
                                // end: "center",
                                scrub: link_scroll ? 1 : false,
                                // pin:true,
                                markers: state.uiconfig.debug && link_scroll

                            }, overwrite: true, transformOrigin: "center center", opacity: 0, ease: "expo.out", duration: .75
                        })

                        //  console.log(comp.value.classList)
                        // comp.value.classlist.remove('animated--waiting')
                        break;
                    case "move-fade":
                        gsap.from(comp.value, {
                            scrollTrigger: {
                                trigger: comp.value,
                                //  start:'top',
                                // end: "50%",
                                // start: "top top",
                                // toggleClass:'animated--waiting',
                                // end: "center",
                                scrub: link_scroll ? 1 : false,
                                // pin:true,
                                markers: state.uiconfig.debug && link_scroll

                            },
                            overwrite: true,
                            transformOrigin: "center center",
                            ...state.animation_values[settings.a.direction], opacity: 0, ease: "expo.out", duration: 1.25
                        })
                        break;
                    case "move-side-fade":
                        gsap.from(comp.value, {
                            scrollTrigger: {
                                trigger: comp.value,
                                // start:comp.value,
                                // end: "50%",
                                // start: "top top",
                                // toggleClass:'animated--waiting',
                                // end: "50%",
                                scrub: link_scroll ? 1 : 0,
                                // pin:true,

                                markers: state.uiconfig.debug && link_scroll

                            }, overwrite: true,
                            transformOrigin: "center center", x: "+=50vw", opacity: 0, ease: "expo.out", duration: .75
                        })
                        break;
                    case "custom":
                        var { duration, easeing, offset_time, offset_x, offset_y, scale, stagger, stagger_time } = settings.a.custom
                        var type_stagger_selectors = {
                            'textfield': ':scope > *',
                            'default': ":scope > [class*='__inner'] > *",
                            'listview': ":scope .animation-child"
                        };

                        var target = stagger ? comp.value.querySelectorAll(type_stagger_selectors[settings.type] || type_stagger_selectors['default']) : comp.value
                        // console.log("custom", settings.a.custom)
                        // console.log(comp.value,type_stagger_selectors[settings.type] || type_stagger_selectors['default'],target)
                        // console.log('dostagger',stagger,target)
                        // console.log(comp.value.querySelectorAll(":scope > [class*='__inner'] > *"));
                        // console.log(target);
                        // if (state.uiconfig.debug) {
                        // console.log("targets:",target)
                        // }
                        // console.log("custom", settings.a.custom,target)
                        var stagger_object = { stagger: parseFloat(stagger_time) }
                        var extras = { start: 'top bottom', end: "bottom top" }
                        // console.log(settings.a.custom)
                        if (settings.a.custom.triggers) {

                            if (settings.a.custom.trigger_start != "default") {
                                extras.start = settings.a.custom.trigger_start == 'custom' ? settings.a.custom.trigger_start_custom : settings.a.custom.trigger_start + " bottom"
                            }
                            if (settings.a.custom.trigger_end != "default") {
                                extras.end = settings.a.custom.trigger_end == 'custom' ? settings.a.custom.trigger_end_custom : settings.a.custom.trigger_end + " top"
                            }
                        }
                        // console.log(extras)
                        var a_object = {
                            scrollTrigger: {
                                trigger: comp.value,
                                // start:comp.value,
                                // end: "50%",
                                //  start: "top",
                                // toggleClass:'animated--waiting',
                                // end: "top",
                                ...extras,
                                scrub: link_scroll ? 1 : false,
                                // pin:true,
                                markers: state.uiconfig.debug

                            },
                            transformOrigin: settings.a.custom.transform_origin || "center center",
                            scale: scale,
                            delay: offset_time,
                            x: offset_x,
                            y: offset_y,
                            overwrite: true,

                            ease: easeing[0] + "." + easeing[1],
                            opacity: settings.a.custom.opacity || 0,
                            rotation: (settings.a.custom.rotation || 0) + 'deg',
                            duration: duration,
                            ...stagger_object
                        }
                        // console.log(a_object)
                        let anim
                        if (settings.a.custom.to)
                            anim = gsap.to(target, a_object)
                        else
                            anim = gsap.from(target, a_object)

                        // console.log(anim)
                        break;
                }
            
            
            comp.value.classList.remove("animated--waiting")
            
            
        }
    }
    return { ...toRefs(state), handleAnimation, cleanUp }
}
